import $ from 'jquery';
import throttle from 'lodash/throttle';
import 'what-input';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBzUaswzhpYUoID2gY8MBBowVd_w0GRt04",
  authDomain: "multitudes-splash.firebaseapp.com",
  projectId: "multitudes-splash",
  storageBucket: "multitudes-splash.appspot.com",
  messagingSenderId: "61035730372",
  appId: "1:61035730372:web:044a20f7417e6ca4604091"
};

// Initialize Firebase
initializeApp(firebaseConfig);

console.log(`
  © ${(new Date()).getFullYear()} Multitudes LLC. All rights reserved.

  Website developed by https://sand.works/
`);

const $toggle = $('.js-toggle');
const $contact = $('.Contact');
const $logo = $('.Logo');

const activeClass = 'Contact--active';
const activeLogoClass = 'Logo--active';
const transitionTime = 1000;

$toggle.on('click', toggleContact);

function toggleContact() {
  if ($contact.hasClass(activeClass)) {
    $contact.attr('aria-hidden', true).fadeOut(transitionTime);
    $logo.removeClass(activeLogoClass);
    $contact.delay(0).removeClass(activeClass);
  } else {
    $contact.removeAttr('aria-hidden').fadeIn(transitionTime);
    $logo.addClass(activeLogoClass);
    $contact.delay(0).addClass(activeClass);
  }
}

const mouseEffect = function(e) {
  const effectDivisor = 7;
  const x = e.screenX;
  const y = e.screenY;

  $('.Contact__background__color').each(function(i, elem) {
    const $elem = $(elem);

    const potentiallyNegativeX = i % 2 ? -1 : 1;
    const left = `${(x / effectDivisor) * potentiallyNegativeX}px`;

    const potentiallyNegativeY = i % 2 ? -1 : 1;
    const top = `${(y / effectDivisor) * potentiallyNegativeY}px`;
    
    $elem.css('margin-left', left);
    $elem.css('margin-top', top);
  })
}
const throttledMouseEffect = throttle(mouseEffect, 250);
window.addEventListener('mousemove', throttledMouseEffect);
